.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 25px;
}

.App-credits {
  vertical-align: center;
}

.carousel-cap {
	background-color: black;
	color: white;
	opacity: 100%;
}

.big-header {
	font-size: 25px !important;
  line-height: normal !important;
}

.small-header {
  font-size: 15px !important;
  line-height: normal !important;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
