body {
  margin: 0;
  padding: 0;
  padding-top: 70px;
  padding-bottom: 80px;
  font-family: sans-serif;
}

.no-underline {
	text-decoration: none !important;
}

.container{
	width: 95% !important;
}

/*.breadcrumb {
	background-color: white !important;
}*/

img.no-vertical-align {
	vertical-align: initial !important;
}

.carousel {
	position: initial !important;
}

.carousel-img {
	display: block;
	margin-left: auto;
  	margin-right: auto;
}

.tech-stack {
	font-size: 50px !important;
}
